
<template>
  <div class="news-index width-100">
    <FloatingActionButton
      color="green"
      icon="fas fa-plus"
      :label="$t('news.create.createNews')"
      :click-function="() => $router.push({ name: 'newsAdminCreate' })"
    />
    <Filters
      :filters="newsFilters"
      filter-key="news"
      :initial-data="filtersStatus.news"
    />
    <TableWrapper
      enable-selection
      :table-columns="newsColumns"
      table-key="news"
      :massive-actions-options="massiveActionsOptions"
      @editNews="(row) => {$router.push({ name: 'newsAdminEdit', params: { newsId: row.id } })}"
      @deleteNews="deleteNews"
      @massiveAction="bulkApplyStatus"
    />
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import { newsColumns, newsFilters } from '@/config';
import { NEWS_STATUSES_DESC } from '@/config/constants';
import { newsApi } from '@/api';
import TableWrapper from '@/components/TableWrapper.vue';
import FloatingActionButton from '@/components/FloatingActionButton.vue';
import Filters from '@/components/Filters.vue';

export default {
  name: 'NewsIndexAdmin',
  components: {
    TableWrapper,
    Filters,
    FloatingActionButton,
  },
  data() {
    return {
      newsColumns,
      newsFilters,
      massiveActionsOptions: Object.keys(NEWS_STATUSES_DESC)
        .map(key => ({ label: `enums.newsStatuses.${NEWS_STATUSES_DESC[key]}`, value: Number(key) })),
    };
  },
  computed: {
    ...mapGetters([
      'filtersStatus',
      'tableFilters',
    ]),
  },
  created() {
    this.parseQueryStringStatus(this.$route.query);
    this.tableRows();
  },
  beforeRouteUpdate(to, from, next) {
    this.parseQueryStringStatus(to.query);
    this.tableRows();
    next();
  },
  methods: {
    ...mapActions([
      'updateTableRows',
      'updateTableCount',
      'parseQueryStringStatus',
    ]),
    async bulkApplyStatus(status, selectedRows) {
      try {
        await newsApi.bulkUpdate({ status }, selectedRows.map(news => news.id));
        this.tableRows();
        this.$notify({
          showClose: true,
          type: 'success',
          message: this.$t('news.update.bulkSuccess'),
        });
      } catch (error) {
        this.$notify({
          showClose: true,
          type: 'error',
          message: error.response.data.error.message,
        });
      }
    },
    async deleteNews(row) {
      try {
        this.$confirm(this.$t('news.delete.confirm'), this.$t('global.warning'), {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })
          .then(async () => {
            await newsApi.delete(row.id);
            this.$notify({ showClose: true, type: 'success', message: this.$t('news.delete.success') });
            await this.tableRows();
          })
          .catch(() => {
            this.$notify({
              type: 'info',
              message: this.$t('news.delete.cancelled'),
            });
          });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async tableRows() {
      try {
        const indexResponse = await newsApi.index(this.tableFilters('news'), this.filtersStatus.news);
        const countResponse = await newsApi.count(this.filtersStatus.news);
        this.updateTableRows({ tableKey: 'news', rows: indexResponse.data });
        this.updateTableCount({ tableKey: 'news', count: countResponse.data.count });
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>
